import React, { useEffect, useState } from 'react';
import { Alert, Col, Row, Card, CardBody } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../common/Loader';
import { isIterableArray } from '../../helpers/utils';
import { useResponsive } from '@farfetch/react-context-responsive';
import { fetchSessionsHistory } from '../../store/features/sessionsHistorySlice';
import JKSessionsHistoryList from '../sessions/JKSessionsHistoryList';
import JKSessionsHistorySwiper from '../sessions/JKSessionsHistorySwiper';

const JKMusicSessionsHistory = () => {
  const { t } = useTranslation();
  const { greaterThan } = useResponsive();
  const dispatch = useDispatch();
  const sessions = useSelector(state => state.sessionsHistory.sessions);
  const loadingStatus = useSelector(state => state.sessionsHistory.status);
  const [offset, setOffset] = useState(0);
  const LIMIT = 50;

  useEffect(() => {
    const options = {
      offset,
      limit: LIMIT
    };
    dispatch(fetchSessionsHistory(options));
  }, [offset]);

  const handleNextPage = () => {
    setOffset(offset + LIMIT);
  };

  return (
    <Card>
      <FalconCardHeader title={t('history.page_title', { ns: 'sessions' })} titleClass="font-weight-bold" />
      <CardBody className="pt-0 pr-0">
        {loadingStatus === 'loading' && sessions.length === 0 ? (
          <Loader />
        ) : isIterableArray(sessions) ? (
          <>
            {greaterThan.sm ? (
              <Row className="mb-3 justify-content-between d-none d-md-block">
                <div className="table-responsive-xl px-2">
                  <JKSessionsHistoryList
                    sessions={sessions}
                    onNextPage={handleNextPage}
                    isLoading={loadingStatus === 'loading'}
                  />
                </div>
              </Row>
            ) : (
              <Row className="swiper-container d-block d-md-none" data-testid="sessionsSwiper">
                <JKSessionsHistorySwiper sessions={sessions} onNextPage={handleNextPage} />
              </Row>
            )}
          </>
        ) : (
          <Row className="p-card">
            <Col>
              <Alert color="info" className="mb-0">
                {t('no_records', { ns: 'common' })}
              </Alert>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default JKMusicSessionsHistory;
