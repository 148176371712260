import { useTranslation } from 'react-i18next';
import moment from 'moment';

const useSessionHelper = (session) => {
  const { t } = useTranslation();

  const sessionDescription = session => {
    if (session.description) {
      return session.description;
    } else if (session.musician_access && !session.approval_required) {
      return t('list.descriptions.public_open_session', { ns: 'sessions' });
    } else if (session.musician_access && session.approval_required) {
      return t('list.descriptions.private_session', { ns: 'sessions' });
    } else if (!session.musician_access && !session.approval_required) {
      return t('list.descriptions.rsvp_session', { ns: 'sessions' });
    }
  };

  const sessionDateTime = session => {
    if(!session.created_at) return '';
    const date = new Date(session.created_at);
    return moment(date).format('dddd, MMMM DD, YYYY, h:mmA z');
  };

  return {
    sessionDescription: sessionDescription(session),
    sessionDateTime: sessionDateTime(session)
  };
};

export default useSessionHelper;