import React, {useEffect} from 'react'

const JKHelpDesk = () => {
  const pageUrl = "https://www.jamkazam.com/help_desk"
  useEffect(() => {
    window.open(pageUrl, '_blank').focus();
    window.history.go(-1)
    return () => {
    };
  }, [])
  return (
    <div>
      opening...
    </div>
  )
}

export default JKHelpDesk