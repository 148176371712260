import React, {useEffect} from 'react'

const JKForum = () => {
  const pageUrl = "https://forum.jamkazam.com/"
  useEffect(() => {
    window.open(pageUrl, '_blank').focus();
    window.history.go(-1)
    return () => {
    };
  }, [])
  return (
    <div>
      opening...
    </div>
  )
}

export default JKForum