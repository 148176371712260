import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getFriends, getPeopleByIds } from '../../helpers/rest'

const initialState = {
  friends: [],
  status: 'idel',
  error: null,
}

export const fetchFriends = createAsyncThunk(
  "friend/fetchFriends", 
  async (options, thunkAPI) => {
    const { userId } = options
    const response = await getFriends(userId);
    return response.json();
  }

)

export const filterFriendsByIds = createAsyncThunk(
  "friend/filterFriendsByIds",
  async (options, thunkAPI) => {
    const { userId, ids } = options;
    const response = await getPeopleByIds({ userId, ids  });
    return response.json();
  }
)

export const FriendSlice = createSlice({
  name: "friend",
  initialState,
  reducers: {
    addFriend: (state) => {},
    removeFriend: (state, action) => {
      state.friends = state.friends.filter(friend => friend.id !== action.payload);
    },
    deleteFriend: (state) => {},
    sortFriends: (state, action) => {
      console.log('sorting friends', action.payload)
    const sortValue = action.payload;
      if(sortValue === 'online'){
      state.friends = state.friends.sort((a, b) => a.online - b.online).sort((a, b) => a.name.localeCompare(b.name));
    } else if(sortValue === 'alphabetical'){
      state.friends = state.friends.sort((a, b) => a.name.localeCompare(b.name));
    } else if(sortValue === 'last_active'){
      state.friends = state.friends.sort((a, b) => b.last_active_timestamp - a.last_active_timestamp);
    }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(filterFriendsByIds.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(filterFriendsByIds.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.friends = action.payload.musicians.sort((a, b) => a.online - b.online).sort((a, b) => a.name.localeCompare(b.name));
      })
      .addCase(filterFriendsByIds.rejected, (state, action) => {
        state.status = 'failed'
        state.error  = action.error.message
      })
  } 
})

export const { sortFriends, removeFriend } = FriendSlice.actions;
export default FriendSlice.reducer;