import React, {useEffect} from 'react'

const JKKnowledgeBase = () => {
  const pageUrl = "https://jamkazam.freshdesk.com/support/home"
  useEffect(() => {
    window.open(pageUrl, '_blank').focus();
    window.history.go(-1)
    return () => {
    };
  }, [])
  return (
    <div>
      opening...
    </div>
  )
}

export default JKKnowledgeBase