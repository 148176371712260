import React, { useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function JKInstrumentIcon({instrumentId, instrumentName}) {
  const InstrumentIcon = useCallback(
    () => {
      let file;
      try {
        file = require(`../../icons/instruments/icon_instrument_${instrumentId.replace(/\s+/g, '_')}.svg`);
      } catch (error) {
        file = require("../../icons/instruments/icon_instrument_other.svg");
      }
      return <img width={25} height={25} src={file} alt={instrumentName} />

    },
    [instrumentId],
  )
  
  return (
    InstrumentIcon()
  )
}

export default JKInstrumentIcon