import React from 'react';
import useUserProfile from '../hooks/useUserProfile';
import { useAuth } from './UserAuth';

// AppDataContext.js
// this context is used to store app data that is shared across the app
const AppDataContext = React.createContext(null);

export const AppDataProvider = ({ children }) => {
  const [appData, setAppData] = React.useState({});
  const { currentUser } = useAuth();
  const { userProfile, photoUrl } = useUserProfile({ user: currentUser, useCache: false });

  React.useEffect(() => {
    setAppData({ userProfile, currentUserPhotoUrl: photoUrl });
  }, [currentUser, userProfile, photoUrl]);

  return <AppDataContext.Provider value={{ appData, setAppData }}>{children}</AppDataContext.Provider>;
};

export const useAppData = () => React.useContext(AppDataContext);