import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const JKLatencyBadge = ({ latencyData, showAll, showBadgeOnly }) => {
  const LATENCY_SCORES = {
    good: { label: 'GOOD', min: 0, max: 40 },
    fair: { label: 'FAIR', min: 40, max: 60 },
    high: { label: 'HIGH', min: 60, max: 10000000 },
    me: { label: 'ME', min: -1, max: -1 },
    unknown: { label: 'UNKNOWN', min: -2, max: -2 }
  };

  const [label, setLabel] = useState('UNKNOWN');
  const [latencyInfo, setLatencyInfo] = useState('');

  const setLatencyLabel = totalLatency => {
    let lbl;
    if (totalLatency >= LATENCY_SCORES['good']['min'] && totalLatency <= LATENCY_SCORES['good']['max']) {
      lbl = LATENCY_SCORES['good']['label'];
    } else if (totalLatency > LATENCY_SCORES['fair']['min'] && totalLatency <= LATENCY_SCORES['fair']['max']) {
      lbl = LATENCY_SCORES['fair']['label'];
    } else if (totalLatency > LATENCY_SCORES['fair']['min'] && totalLatency <= LATENCY_SCORES['fair']['max']) {
      lbl = LATENCY_SCORES['fair']['label'];
    } else if (totalLatency > LATENCY_SCORES['high']['min']) {
      lbl = LATENCY_SCORES['high']['label'];
    } else {
      lbl = LATENCY_SCORES['unknown']['label'];
    }
    setLabel(lbl);
  };

  useEffect(() => {
    if (latencyData) {
      if(
        (latencyData.ars_internet_latency === "" || parseFloat(latencyData.ars_internet_latency) === NaN) || 
        (latencyData.audio_latency === "" || parseFloat(latencyData.audio_latency) === NaN)){
          setLatencyLabel(LATENCY_SCORES['unknown']['min']);
          setLatencyInfo("");
          return;
      }
      const tot = Math.round(latencyData.ars_internet_latency) + Math.round(latencyData.audio_latency);
      setLatencyLabel(tot);

      if (showAll) {
        setLatencyInfo(
          `${Math.round(latencyData.ars_internet_latency)}ms (Internet) + ${Math.round(
            latencyData.audio_latency
          )}ms (audio) = ${tot}ms (total) `
        );
      } else {
        setLatencyInfo(`${tot}ms`);
      }
    }
  }, [latencyData]);

  return showBadgeOnly ? (
    <span className={`badge latency-badge latency-${label.toLowerCase()}`}>{label}</span>
  ) : (
    <>
      <span className="mr-1">{latencyInfo}</span>
      <span className={`badge latency-badge latency-${label.toLowerCase()}`}>{label}</span>
    </>
  );
};

JKLatencyBadge.propTypes = {
  latencyData: PropTypes.shape({
    ars_internet_latency: PropTypes.number.isRequired,
    audio_latency: PropTypes.number.isRequired
  }),
  showAll: PropTypes.bool,
  showBadgeOnly: PropTypes.bool
};

JKLatencyBadge.defaultProps = {
  showAll: false,
  showBadgeOnly: false
};

export default JKLatencyBadge;
