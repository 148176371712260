import React, { useState, useEffect } from 'react';
import { useResponsive } from '@farfetch/react-context-responsive';
import useSessionHelper from './JKUseSessionHelper';
import { Row, Col, UncontrolledTooltip } from 'reactstrap';
import JKInstrumentIcon from '../profile/JKInstrumentIcon';
import JKSessionUser from './JKSessionUser';
import JKUserLatencyBadge from '../profile/JKUserLatencyBadge';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../context/UserAuth';
import { fetchUserLatencies } from '../../store/features/latencySlice';
import { useDispatch } from 'react-redux';

const JKSessionsHistoryItem = ({ session_id, sessionGroup }) => {
  const { greaterThan } = useResponsive();
  const { sessionDescription, sessionDateTime } = useSessionHelper(sessionGroup[0]);
  const [participants, setParticipants] = useState([]);
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('sessionGroup', sessionGroup);
    setParticipants(
      sessionGroup.map(history => {
        const participant = {
          id: history.user_id,
          user: {
            id: history.user_id,
            photo_url: history.photo_url,
            first_name: history.first_name,
            last_name: history.last_name,
            name: `${history.first_name} ${history.last_name}`
          }
        };
        if (history.instruments) {
          participant.tracks = history.instruments.split('|').map((instrument, index) => ({
            id: index,
            instrument_id: instrument,
            instrument: instrument
          }));
        }

        return participant;
      })
    );
  }, [sessionGroup]);

  useEffect(() => {
    if (participants.length > 0 && currentUser) {
      const currentUserId = currentUser.id;
      const otherUserIds = participants.filter(p => p.id !== currentUserId).map(p => p.id);
      dispatch(fetchUserLatencies({ currentUserId, otherUserIds }));
    }
  }, [participants]);

  // const formattedDate = date => {
  //   const d = new Date(date);
  //   return d.toLocaleDateString('en-us', {
  //     weekday: 'long',
  //     year: 'numeric',
  //     month: 'short',
  //     day: 'numeric',
  //     timeZoneName: 'short'
  //   });
  // };

  const musicianRowStyle = {
    height: '40px',
    flexWrap: 'wrap',
    overflow: 'hidden',
    alignItems: 'center'
  };

  return (
    <>
      {greaterThan.sm ? (
        <tr key={session_id}>
          <td>
            <div>
              <u>
                <small>{sessionDateTime}</small>
              </u>
            </div>
            <div>{sessionDescription}</div>
          </td>
          <td>
            {participants.map(participant => (
              <Row style={musicianRowStyle} key={participant.id}>
                <Col>
                  <JKSessionUser user={participant.user} />
                </Col>
              </Row>
            ))}
          </td>
          <td className="text-center">
            {participants.map(participant => (
              <Row key={participant.id} style={musicianRowStyle}>
                <Col>
                  {participant.id !== currentUser.id ? (
                    <JKUserLatencyBadge key={participant.id} user={participant.user} showBadgeOnly={true} />
                  ) : (
                    <span />
                  )}
                </Col>
              </Row>
            ))}
          </td>
          <td>
            {participants.map(participant => (
              <Row style={musicianRowStyle} key={participant.id} data-testid={`Participant${participant.id}Tracks`}>
                <Col>
                  {participant.tracks &&
                    participant.tracks.map(track => (
                      <span key={track.id} className="mr-1 mb-1" title={track.instrment}>
                        <a
                          id={`Participant${participant.id}Track${track.id}Instrument`}
                          data-testid={`Track${track.id}Instrument`}
                        >
                          <JKInstrumentIcon instrumentId={track.instrument_id} instrumentName={track.instrument} />
                        </a>
                        <UncontrolledTooltip
                          placement="top"
                          target={`Participant${participant.id}Track${track.id}Instrument`}
                        >
                          {track.instrument}
                        </UncontrolledTooltip>
                      </span>
                    ))}
                </Col>
              </Row>
            ))}
          </td>
        </tr>
      ) : (
        <Row>
          <Col>
            <div>
              <u>
                <small>{sessionDateTime}</small>
              </u>
            </div>
            <div>{sessionDescription}</div>
            <div className="d-flex flex-row justify-content-between mt-3">
              <div className="ml-0 ms-2">
                <h5>{t('list.header.musicians', { ns: 'sessions' })}</h5>
              </div>
              <div className="ml-2 ms-2">
                <strong>{t('list.header.latency', { ns: 'sessions' })}</strong>
              </div>
            </div>
            <div>
              {participants.map(participant => (
                <JKSessionUser user={participant.user} />
              ))}
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default JKSessionsHistoryItem;
