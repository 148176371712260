import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

const JKEditProfileButton = ({ onClick, dataTestId, cssClasses, title, children }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  return (
    <>
      <a href="/#" onClick={onClick} data-testid={dataTestId} id="btn-edit">
        <span className={cssClasses} data-bs-toggle="tooltip" title={title}>
          {children}
        </span>
      </a>
      <Tooltip placement="top" isOpen={tooltipOpen} target="btn-edit" toggle={toggleTooltip}>
        Show profile details
      </Tooltip>
    </>
  );
};

export default JKEditProfileButton;
