import React from 'react';
import PropTypes from 'prop-types';

import {useAuth} from '../../context/UserAuth';
import { useDispatch } from 'react-redux';
import { removeNotification } from '../../store/features/notificationSlice';

import JKGenericNotification from './JKGenericNotification';
import JKFriendRequestNotification from './JKFriendRequestNotification';
import TextMessageNotification from './JKTextMessageNotification';
import classNames from 'classnames';
import { toast } from 'react-toastify';

function JKNotification(props) {
  
  const { description, notification_id } = props.notification
  
  const dispatch = useDispatch();
  const { currentUser } = useAuth()
  
  const handleOnAccept = async () => {
    const options = { 
      userId: currentUser.id, 
      notificationId: notification_id 
    }
    //console.log('before removeNotification', options);
    await dispatch(removeNotification(options))
    .unwrap()
    .then(resp => {
      toast.success('Friend request accepted');
    })
    .catch(error => {
      toast.error('Error accepting friend request');
    })
  }

  

  const NOTIFICATION_TYPES = {
    TEXT_MESSAGE: 'TEXT_MESSAGE',
    FRIEND_REQUEST: 'FRIEND_REQUEST'
  };

  const render = () => {
    switch (description) {
      case NOTIFICATION_TYPES.TEXT_MESSAGE:
        return <TextMessageNotification notification={props.notification } handleOnAccept={handleOnAccept} />;
      case NOTIFICATION_TYPES.FRIEND_REQUEST:
        return <JKFriendRequestNotification notification={props.notification} handleOnAccept={handleOnAccept} />;
      default:
        return <JKGenericNotification {...props.notification} />;
    }
  };

  return (<a className={classNames('notification', props.classNames) }>{render()}</a>);

};

JKNotification.propTypes = {
  notification: PropTypes.object.isRequired
}

// JKNotification.propTypes = {
//   // to: PropTypes.string.isRequired,
//   // avatar: PropTypes.shape(Avatar.propTypes),
//   // time: PropTypes.string.isRequired,
//   // className: PropTypes.string,
//   // unread: PropTypes.bool,
//   // flush: PropTypes.bool,
//   // emoji: PropTypes.string,
//   // children: PropTypes.node
// };

//JKNotification.defaultProps = { unread: false, flush: false };

export default JKNotification;
