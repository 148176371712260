import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '../../context/UserAuth';
import { truncate } from '../../helpers/utils';
import { fetchPerson } from '../../store/features/peopleSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useResponsive } from '@farfetch/react-context-responsive';
import { useBrowserQuery } from '../../context/BrowserQuery';

import JKProfileSidePanel from '../profile/JKProfileSidePanel';
import JKProfileAvatar from '../profile/JKProfileAvatar';
import JKProfileInstrumentsList from '../profile/JKProfileInstrumentsList';
import JKProfileGenres from '../profile/JKProfileGenres';
import JKConnectButton from '../profile/JKConnectButton';
import JKMessageButton from '../profile/JKMessageButton';
import JKLatencyBadge from '../profile/JKLatencyBadge';
import JKLastActiveAgo from '../profile/JKLastActiveAgo';
import JKEditProfileButton from '../profile/JKEditProfileButton';

const JKPerson = props => {
  const {
    id,
    name,
    biography,
    photo_url,
    instruments,
    genres,
    latency_data,
    last_active_timestamp,
    online
  } = props.person;
  const { currentUser } = useAuth();
  const [showSidePanel, setShowSidePanel] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(state => state.people.people.find(p => p.id === id));
  const latencyData = useSelector(state => state.latency.latencies.find(l => l.user_id === id));
  const { greaterThan } = useResponsive();

  const toggleMoreDetails = async e => {
    if (e) e.preventDefault();
    try {
      await dispatch(fetchPerson({ userId: id })).unwrap();
    } catch (error) {
      console.log(error);
    }
    setShowSidePanel(prev => !prev);
  };

  const queryString = useBrowserQuery();

  useEffect(() => {
    const openWin = queryString.get('open');
    const userId = queryString.get('id');
    //showing user more details if directly reqested to do so
    //by query string params (coming from weekly new user match email link)
    if (openWin && userId && userId === id) {
      if (openWin === 'details' || openWin === 'connect') {
        toggleMoreDetails();
      }
    }
  }, []);

  // const linkClass = useMemo(() => {
  //   if (!online) {
  //     return `d-flex align-items-center mb-1 fs-0 person-link offline`;
  //   }
  //   return `d-flex align-items-center mb-1 fs-0 person-link`;
  // }, [online]);

  return (
    <>
      {greaterThan.sm ? (
        <tr className="align-middle person-row" key={`people-list-item-${id}`}>
          <td className="text-nowrap">
            <a href="/#" onClick={toggleMoreDetails} className="d-flex align-items-center mb-1 fs-0 person-link">
              <div className="avatar avatar-xl">
                <JKProfileAvatar src={photo_url} />
              </div>
              <div className="ml-2 ms-2">
                <strong>{name}</strong>
              </div>
            </a>
            <div>
              <strong className="mr-1">{t('person_attributes.status', { ns: 'people' })}:</strong>
              <span>{online ? 'Online' : 'Offline'}</span>
            </div>
            <div>
              <strong className="mr-1">{t('person_attributes.latency_to_me', { ns: 'people' })}:</strong>
              <JKLatencyBadge latencyData={latency_data || latencyData} />
            </div>
            <div>
              <strong className="mr-1">{t('person_attributes.last_active', { ns: 'people' })}:</strong>{' '}
              <JKLastActiveAgo timestamp={last_active_timestamp} />
            </div>
          </td>
          <td data-testid="biography-col">
            <div dangerouslySetInnerHTML={{ __html: truncate(biography, 310) }} />
            {biography && biography.length > 200 && (
              <a href="/#" data-testid="linkMore" onClick={toggleMoreDetails}>
                {'  '} {t('more', { ns: 'common' })} »
              </a>
            )}
          </td>
          <td data-testid="instruments-col">
            <JKProfileInstrumentsList instruments={instruments} toggleMoreDetails={toggleMoreDetails} />
          </td>
          <td data-testid="genres-col">
            <JKProfileGenres genres={genres} toggleMoreDetails={toggleMoreDetails} />
          </td>
          <td className="text-nowrap">
            <JKConnectButton
              currentUser={currentUser}
              user={props.person}
              addContent={<FontAwesomeIcon icon="plus" transform="shrink-4 down-1" className="mr-1" />}
              removeContent={<FontAwesomeIcon icon="minus" transform="shrink-4 down-1" className="mr-1" />}
              cssClasses="fs--1 px-2 py-1 mr-1"
            />

            <JKMessageButton currentUser={currentUser} user={props.person} cssClasses="fs--1 px-2 py-1 mr-1">
              <FontAwesomeIcon icon="comments" transform="shrink-4 down-1" className="mr-1" />
            </JKMessageButton>

            <JKEditProfileButton
              onClick={toggleMoreDetails}
              dataTestId="btnMore"
              cssClasses="btn btn-primary fs--1 px-2 py-1"
              title={t('view_profile', { ns: 'people' })}
            >
              <FontAwesomeIcon icon="user" transform="shrink-4 down-1" className="mr-1" />
            </JKEditProfileButton>
          </td>
        </tr>
      ) : (
        <>
          <Row>
            <Col>
              <div>
                <strong className="mr-1">{t('person_attributes.status', { ns: 'people' })}:</strong>
                <span>{online ? 'Online' : 'Offline'}</span>
              </div>
              <div>
                <strong className="mr-1">{t('person_attributes.latency_to_me', { ns: 'people' })}:</strong>{' '}
                <JKLatencyBadge latencyData={latency_data} />
              </div>
              <div>
                <strong className="mr-1">{t('person_attributes.last_active', { ns: 'people' })}:</strong>{' '}
                <JKLastActiveAgo timestamp={last_active_timestamp} />
              </div>
            </Col>
            <Col className="d-none d-sm-block">
              <h5>{t('person_attributes.instruments', { ns: 'people' })}</h5>
              <JKProfileInstrumentsList instruments={instruments} toggleMoreDetails={toggleMoreDetails} />
            </Col>
          </Row>
          <Row className="d-block d-sm-none mt-3">
            <Col>
              <h5>{t('person_attributes.instruments', { ns: 'people' })}</h5>
              <JKProfileInstrumentsList instruments={instruments} toggleMoreDetails={toggleMoreDetails} />
            </Col>
          </Row>
          <Row className="d-block d-sm-none mt-3">
            <Col>
              <h5>{t('person_attributes.genres', { ns: 'people' })}</h5>
              <JKProfileGenres genres={genres} toggleMoreDetails={toggleMoreDetails} />
            </Col>
          </Row>
          <br />
          <Row data-testid="mobBiography">
            <Col>
              <h5>{t('person_attributes.about', { ns: 'people' })}</h5>
              <div>{biography}</div>
            </Col>
          </Row>
          <br />

          <JKConnectButton
            currentUser={currentUser}
            user={props.person}
            addContent={<FontAwesomeIcon icon="plus" transform="shrink-4 down-1" className="mr-1" />}
            removeContent={<FontAwesomeIcon icon="minus" transform="shrink-4 down-1" className="mr-1" />}
            cssClasses="fs--1 px-2 py-1 mr-1"
          />

          <JKMessageButton currentUser={currentUser} user={props.person} cssClasses="fs--1 px-2 py-1 mr-1">
            <FontAwesomeIcon icon="comments" transform="shrink-4 down-1" className="mr-1" />
          </JKMessageButton>

          {/* <a href="/#" onClick={toggleMoreDetails} data-testid="btnMore">
            <span
              className="btn btn-primary fs--1 px-2 py-1"
              data-bs-toggle="tooltip"
              title={t('view_profile', { ns: 'people' })}
            >
              <FontAwesomeIcon icon="user" transform="shrink-4 down-1" className="mr-1" />
            </span>
          </a> */}

          <JKEditProfileButton
            onClick={toggleMoreDetails}
            dataTestId="btnMore"
            cssClasses="btn btn-primary fs--1 px-2 py-1"
            title={t('view_profile', { ns: 'people' })}
          >
            <FontAwesomeIcon icon="user" transform="shrink-4 down-1" className="mr-1" />
          </JKEditProfileButton>
        </>
      )}
      <JKProfileSidePanel user={user} latencyData={latency_data} show={showSidePanel} setShow={setShowSidePanel} />
    </>
  );
};

JKPerson.propTypes = {
  person: PropTypes.object.isRequired
};

export default JKPerson;
