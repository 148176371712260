import React from 'react';
import PropTypes from 'prop-types';
import { groupByKey } from '../../helpers/utils';
import JKSessionsHistoryItem from './JKSessionsHistoryItem';
import { useTranslation } from 'react-i18next';

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

import { Card, CardBody, CardHeader } from 'reactstrap';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const JKSessionsHistorySwiper = ({ sessions, onNextPage }) => {
  const sessionsById = groupByKey(sessions, 'session_id');
  const { t } = useTranslation();

  return (
    <>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        //onSlideChange={() => console.log('slide change')}
        onSlideNextTransitionEnd={swiper => {
          if(swiper.isEnd){
            onNextPage()
          }
        }}
        pagination={{
          clickable: true,
          type: 'custom'
        }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }}
      >
        {Object.entries(sessionsById).map(([session_id, sessionGroup]) => (
          <SwiperSlide key={`sessions-swiper-item-${session_id}`}>
            <Card className="swiper-card">
              <CardHeader className="text-center bg-200">
                <h4 className="d-inline-block align-center mt-1">{t("history.header.session", {ns: 'sessions'})}</h4>
              </CardHeader>
              <CardBody>
                <JKSessionsHistoryItem key={session_id} session_id={session_id} sessionGroup={sessionGroup} />
              </CardBody>
            </Card>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="py-4 px-6 bg-white border-top w-100 fixed-bottom">
        <div className="swiper-pagination" />
        <div className="swiper-button-prev" />
        <div className="swiper-button-next" />
      </div>
    </>
  );
};

JKSessionsHistorySwiper.propTypes = {
  sessions: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  onNextPage: PropTypes.func.isRequired
};

export default JKSessionsHistorySwiper;
