import React from 'react';
import { useAuth } from '../../context/UserAuth';
import { useDispatch, useSelector } from 'react-redux';
import JKMessageButton from '../profile/JKMessageButton';
import ProfileAvatar from '../profile/JKProfileAvatar';
import TimeAgo from '../common/JKTimeAgo';
import { truncate } from '../../helpers/utils';
import useUserProfile from '../../hooks/useUserProfile';

function JKTextMessageNotification(props) {
  const { source_user, source_user_id, message, created_at } = props.notification;
  const { currentUser } = useAuth();
  const user = useSelector(state => state.people.people.find(person => person.id === source_user_id));
  const { photoUrl } = useUserProfile({ user }); // user is the person who sent the message

  return (
    <>
      <div className="notification-avatar mr-3">
        <ProfileAvatar src={photoUrl} />
      </div>
      <div className="notification-body">
        <p className="mb-1">
          {source_user.name} said: {truncate(message, 120)}
        </p>
        <span className="notification-time">
          <TimeAgo date={created_at} />
        </span>
        <div>
          {user && (
            <JKMessageButton
              currentUser={currentUser}
              user={user}
              cssClasses="fs--1 px-2 py-1 mr-1"
              color="secondary"
              size="sm"
              outline={true}
            >
              <span>Reply</span>
            </JKMessageButton>
          )}
        </div>
      </div>
    </>
  );
}

export default JKTextMessageNotification;
